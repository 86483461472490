import Vue from 'vue';
import App from './App.vue';
import VueResource from 'vue-resource';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueTheMask from 'vue-the-mask';
import Maska from 'maska';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Snackbar from '@/components/Snackbar';

if (process.env.VUE_APP_DISABLE_LOGS === 'true') {
    console.log = function() {};
    console.error = function() {};
}

Vue.component('Snackbar', Snackbar);
Vue.config.productionTip = false;

Vue.use(Maska);
Vue.use(VueTheMask);
Vue.use(VueLoading);
Vue.use(VueResource);

Vue.http.options.root = process.env.VUE_APP_RECUPERI_API;

Vue.http.interceptors.push((request, next) => {
    if (
        request.url != 'login' &&
        !request.url.includes(process.env.VUE_APP_URL_BACK_OFFICE)
    ) {
        request.headers.set('Authorization', 'Bearer ' + store.getters.token);
        request.headers.set('X-Custom-Header', 'foobar');
        request.headers.set('X-Permitted-Cross-Domain-Policies', 'none');
        request.headers.set('Content-Security-Policy', 'default-src self');
        request.headers.set(
            'Strict-Transport-Security',
            'max-age=31536000; includeSubDomains'
        );
        request.headers.set('X-Frame-Options', 'SAMEORIGIN');
        request.headers.set('X-XSS-Protection', '1');
        request.headers.set('X-Content-Type-Options', 'nosniff');
        request.headers.set('X-Download-Options', 'noopen');
    }
    if (request.url.includes(process.env.VUE_APP_URL_BACK_OFFICE)) {
        request.headers.set(
            'Authorization',
            'Token ' + process.env.VUE_APP_TOKEN_BACK_OFFICE
        );
    }
    next(response => {
        let storeDados = store;
        if (
            response.status == 401 &&
            storeDados.state.isAuthenticated === true
        ) {
            console.log(
                'Seu Token expirou! Um novo acesso é necessário! | Session expired! Authentication is required!'
            );
            storeDados.state.tokenExpirou = true;
            store.dispatch('sessaoExpirou');
            localStorage.removeItem('filtroTitulos');
            localStorage.removeItem('resultadoTitulos');
            localStorage.removeItem('outrosFiltros');
            localStorage.removeItem('recuperi-front-vue');
            sessionStorage.setItem('last-url', router.currentRoute.fullPath);
            router.push('/login');
            router.go();
        }
    });
});

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');
