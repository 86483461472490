var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"924px","persistent":"","scrollable":"","color":"background","transition":"dialog-bottom-transition"},model:{value:(_vm.dialogModalRelatorios),callback:function ($$v) {_vm.dialogModalRelatorios=$$v},expression:"dialogModalRelatorios"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":"","disabled":_vm.loadingSolicitar},on:{"click":function($event){return _vm.fecharModal()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Relatórios Solicitados")])],1)],1),_c('v-divider'),_c('div',{staticClass:"pa-4"},[_c('v-card-text',{class:!_vm.isExtraSmall ? 'px-0' : 'px-0 pt-0'},[_vm._v(" Consulte o histórico e realize o download dos seus Relatórios solicitados ")]),_c('v-data-table',{staticClass:"elevation-1 pa-3",class:_vm.isExtraSmall ? 'alinhar-botao-editar' : '',attrs:{"headers":_vm.colunasRelatoriosSolicitados,"items":_vm.relatoriosSolicitados,"loading":_vm.loadingInicial,"loading-text":"Carregando Relatórios...","item-key":"relatorios","footer-props":{
                    itemsPerPageOptions: [10, 20, 50, -1],
                    itemsPerPageText: 'Relatórios por página:'
                },"data-cy":"tabelaRelatorioSolicitados"},scopedSlots:_vm._u([{key:"item.relatorio_data_solicitacao",fn:function(props){return [_vm._v(" "+_vm._s(props.item.relatorio_data_solicitacao ? _vm.formatarDataHoraMoment( props.item.relatorio_data_solicitacao, 'DD/MM/YYYY HH:mm:ss' ) : '--')+" ")]}},{key:"item.action",fn:function(props){return [_c('v-btn',{staticClass:"green white--text",class:!_vm.isExtraSmall ? '' : 'mb-2 mr-3',attrs:{"elevation":"0","loading":_vm.loadingSolicitar,"data-cy":`baixarRelatorio${props.index}`,"disabled":!props.item.relatorio_link ||
                                props.item.relatorio_link === ''},on:{"click":function($event){return _vm.baixarRelatorio(props.item.relatorio_link)}}},[_c('v-icon',{staticClass:"mr-2",style:({ color: 'white' }),attrs:{"medium":""}},[_vm._v("download")]),_vm._v("Baixar ")],1)]}}])})],1),_c('v-divider'),_c('v-card-actions',{style:(!_vm.isExtraSmall
                    ? 'display: flex; justify-content: end'
                    : 'display: flex; justify-content: center')},[_c('v-btn',{staticClass:"ml-5",attrs:{"dense":"","color":"error","width":_vm.isExtraSmall ? '100' : '120',"loading":_vm.loadingSolicitar},on:{"click":function($event){return _vm.fecharModal()}}},[_vm._v(" Cancelar ")])],1)],1),(_vm.mostrarSnackbar)?_c('Snackbar',{attrs:{"mostrarSnackbar":_vm.mostrarSnackbar,"timeoutSnackbar":6000,"corSnackbar":_vm.corSnackbar,"mensagemSnackbar":_vm.mensagemSnackbar},on:{"fecharSnackbar":function($event){_vm.mostrarSnackbar = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }