import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
import VueI18n from 'vue-i18n';

Vue.use(Vuetify);
Vue.use(VueI18n);

const messages = {
    pt: {
        $vuetify: {
            close: 'Fechar',
            dataIterator: {
                rowsPerPageText: 'Itens por página',
                loadingText: 'Buscando...'
            },
            dataFooter: {
                pageText: '{0}-{1} de {2}',
                itemsPerPageAll: 'Todos',
                prevPage: 'Anterior',
                nextPage: 'Próximo'
            },
            dataTable: {
                itemsPerPageText: 'Itens por página:',
                prevPage: 'Anterior',
                nextPage: 'Próximo'
            },
            noDataText: 'Não há resultados'
        }
    }
};

// Create VueI18n instance with options
const i18n = new VueI18n({
    locale: 'pt',
    silentTranslationWarn: true, // as mensagens que não forem traduzidas, não irão gerar alertas.
    messages
});

export default new Vuetify({
    icons: {
        iconfont: 'mdi'
    },
    lang: {
        t: (key, ...params) => i18n.t(key, params)
    },
    theme: {
        options: { cspNonce: 'dQw4w9WgXcQ' },
        themes: {
            light: {
                primary: colors.cyan.lighten1,
                secondary: colors.cyan.darken1,
                accent: colors.white,
                error: colors.red.darken3,
                warning: colors.yellow,
                background: '#EEEEEE'
            }
        }
    }
});
