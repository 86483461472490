import axios from 'axios';

export default class titulosHistoricoDataTableService {
    constructor(http, store) {
        this._http = http;
        this._store = store;
        this._ctrl = store.getters.getAbortCtrl;
    }
    // buscaOfertas() {
    //     return this._http.post('consultas/renegociacoes_ativas', {
    //         cd_apresentante: this._store.getters.cdApresentante
    //     });
    // }

    // dadosHistoricoTitulos(dt_inicio, dt_fim) {
    //     return this._http.post('datatable/historicotitulos', {
    //         cd_apresentante: this._store.getters.cdApresentante,
    //         dt_inicio: dt_inicio,
    //         dt_fim: dt_fim
    //     });
    // }

    buscaOfertas() {
        return axios.post(
            process.env.VUE_APP_RECUPERI_API + 'consultas/renegociacoes_ativas',
            {
                cd_apresentante: this._store.getters.cdApresentante
            },
            {
                headers: {
                    Authorization: 'Bearer ' + this._store.getters.token
                },
                signal: this._ctrl.signal
            }
        );
    }

    dadosHistoricoTitulos() {
        return axios.get(
            process.env.VUE_APP_RECUPERI_API +
                'datatable/vl-total-titulos-por-status',
            {
                params: {
                    cd_apresentante: this._store.getters.cdApresentante
                },
                headers: {
                    Authorization: 'Bearer ' + this._store.getters.token
                },
                signal: this._ctrl.signal
            }
        );
    }
}
