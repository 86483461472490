<template>
    <div class="home">
        <v-flex>
            <v-row class="ma-3 px-3 pt-3 pb-0">
                <v-col class="mx-auto mt-5" cols="12" xs="6" lg="12">
                    <v-card
                        :color="colorPainel"
                        shaped
                        outlined
                        block
                        elevation="1"
                        max-width="calc(100%)"
                    >
                        <v-card-title
                            v-bind:style="{ color: dashboardColorFontTitle }"
                            class="title font-weight-normal ml-5"
                        >
                            <v-icon class="mr-3" color="white">
                                mdi-dots-grid
                            </v-icon>
                            Olá {{ computedGetUserName }}. Bem vindo ao Painel
                            Recuperi
                        </v-card-title>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="mx-6 mt-0 mb-4">
                <v-col cols="12" xs="12" lg="6" class="mt-6 pt-0 px-4 pb-4">
                    <v-card
                        style="height: 100%; display: grid; grid-template-rows: repeat(2, 1fr);"
                    >
                        <v-sheet
                            class="v-sheet--offset mx-auto"
                            :color="colorPainel"
                            elevation="1"
                            width="calc(100% - 32px)"
                        >
                            <Loading
                                :active="carregandoTotalUploads"
                                :is-full-page="false"
                            />
                            <div class="ma-3 pa-3">
                                <chartTotalUploads
                                    class="mt-5"
                                    :height="150"
                                    color="red"
                                    :chart-data="dataTotalUpload"
                                >
                                </chartTotalUploads>
                            </div>
                        </v-sheet>
                        <div
                            class="px-4"
                            style="display: grid; grid-template-rows: 0.7fr 0.6fr 0.5fr;"
                        >
                            <div
                                v-bind:style="{ color: titleColorFont }"
                                class="title font-weight-bold mb-2 d-flex align-center"
                            >
                                Upload de Títulos Efetivados no Sistema
                            </div>
                            <div class="font-weight-regular grey--text caption">
                                Dados pertencentes a somatória de todos os
                                títulos com upload efetivado no sistema dentro
                                dos últimos 12 meses
                                <b>(títulos irregulares não inclusos)</b>
                            </div>
                            <div>
                                <v-divider class="my-2"></v-divider>
                                <v-icon class="mr-2" small>mdi-clock</v-icon>
                                <span
                                    class="caption grey--text font-weight-light"
                                    >Atualizado desde o último acesso</span
                                >
                            </div>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12" xs="12" lg="6" class="mt-6 pt-0 px-4 pb-4">
                    <v-card
                        style="height: 100%; display: grid; grid-template-rows: repeat(2, 1fr);"
                    >
                        <v-sheet
                            class="v-sheet--offset mx-auto"
                            :color="colorPainel"
                            elevation="1"
                            width="calc(100% - 32px)"
                        >
                            <Loading
                                :active="carregandoChartRecuperados"
                                :is-full-page="false"
                            />
                            <div class="ma-3 pa-3">
                                <chartRecuperados
                                    class="mt-5"
                                    :height="150"
                                    color="red"
                                    :chart-data="dataRecuperados"
                                >
                                </chartRecuperados>
                            </div>
                        </v-sheet>
                        <div
                            class="px-4"
                            style="display: grid; grid-template-rows: 0.7fr 0.6fr 0.5fr;"
                        >
                            <div
                                v-bind:style="{ color: titleColorFont }"
                                class="title font-weight-bold mb-2 d-flex align-center"
                            >
                                Títulos com Desistências, Cancelamentos,
                                Anuências e Pagos em Cartório
                            </div>
                            <div
                                class="font-weight-regular grey--text caption "
                            >
                                Dados dos últimos 12 meses de títulos com os
                                seguintes comandos: 231 (Enviado à desistência),
                                241 (Enviado à Cancelamento), 251 (Enviado à
                                Anuência), 101 (Pagos) e 107 (Liquidação em
                                condicional)
                            </div>
                            <div>
                                <v-divider class="my-2"></v-divider>
                                <v-icon class="mr-2" small> mdi-clock </v-icon>
                                <span
                                    class="caption grey--text font-weight-light"
                                    >Atualizado desde o último acesso</span
                                >
                            </div>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="ma-3 pa-3">
                <v-col cols="12" xs="12" lg="12">
                    <v-card class="mt-4 mx-auto">
                        <v-sheet
                            class="v-sheet--offset mx-auto"
                            :color="colorPainel"
                            elevation="1"
                            width="calc(100% - 32px)"
                        >
                            <v-card-title
                                class="white--text title font-weight-bold mb-2"
                            >
                                Títulos Existentes
                            </v-card-title>
                        </v-sheet>
                        <v-data-table
                            v-intersect="onIntersect"
                            :headers="headers"
                            :items="titulosExistentes"
                            :header-props="headerProps"
                            :loading="carregandoDataTable"
                            hide-default-footer
                            disable-pagination
                            class="elevation-1"
                        >
                            <template v-slot:[`item.ds_status`]="{ item }">
                                {{ item.ds_status }}
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            color="grey"
                                            v-bind="attrs"
                                            v-on="on"
                                            class="my-auto ml-2"
                                        >
                                            help
                                        </v-icon>
                                    </template>
                                    <span>{{
                                        getTooltipByStatus(item.ds_status)
                                    }}</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="ma-3 pa-3">
                <v-col cols="12" xs="12" lg="12">
                    <v-card class="mt-4 mx-auto">
                        <v-row>
                            <v-col sm="6" lg="4">
                                <v-sheet
                                    class="v-sheet--offset ml-3"
                                    :color="colorPainel"
                                    elevation="12"
                                    max-width="fit-content"
                                >
                                    <v-icon
                                        color="white"
                                        size="300%"
                                        class="ma-3 pa-3"
                                    >
                                        email
                                    </v-icon>
                                </v-sheet>
                            </v-col>
                            <v-col
                                cols="12"
                                xs="12"
                                sm="12"
                                lg="8"
                                class="d-flex align-center justify-center"
                            >
                                <v-card-text class="pt-0">
                                    <div
                                        :style="{
                                            color: titleColorFont
                                        }"
                                        style="font-size: 1.7rem"
                                        class="font-weight-bold"
                                    >
                                        Relatório de E-mails dos últimos 90 dias
                                    </div>
                                </v-card-text>
                            </v-col>
                        </v-row>
                        <v-col>
                            <v-row>
                                <v-col sm="6" lg="3" style="display: grid">
                                    <div class="grey--text">
                                        E-mails Enviados
                                    </div>
                                    <div
                                        :style="{
                                            color: titleColorFont
                                        }"
                                        class="font-weight-bold display-1 d-flex justify-center align-end"
                                    >
                                        <v-progress-circular
                                            v-if="carregandoRelatorioEmails"
                                            color="primary"
                                            small
                                            indeterminate
                                        />
                                        <div v-else>
                                            {{
                                                relatorioEmails.emails_enviados
                                            }}
                                        </div>
                                    </div>
                                </v-col>
                                <v-col sm="6" lg="3" style="display: grid">
                                    <div class="grey--text">
                                        E-mails Recebidos
                                    </div>
                                    <div
                                        :style="{
                                            color: titleColorFont
                                        }"
                                        class="font-weight-bold display-1 d-flex justify-center align-end"
                                    >
                                        <v-progress-circular
                                            v-if="carregandoRelatorioEmails"
                                            color="primary"
                                            small
                                            indeterminate
                                        />
                                        <div v-else>
                                            {{
                                                relatorioEmails.emails_recebidos
                                            }}
                                        </div>
                                    </div>
                                </v-col>
                                <v-col sm="6" lg="3" style="display: grid">
                                    <div class="grey--text">
                                        E-mails Abertos
                                    </div>
                                    <div
                                        :style="{
                                            color: titleColorFont
                                        }"
                                        class="font-weight-bold display-1 d-flex justify-center align-end"
                                    >
                                        <v-progress-circular
                                            v-if="carregandoRelatorioEmails"
                                            color="primary"
                                            small
                                            indeterminate
                                        />
                                        <div v-else>
                                            {{ relatorioEmails.emails_abertos }}
                                        </div>
                                    </div>
                                </v-col>
                                <v-col sm="6" lg="3" style="display: grid">
                                    <div class="grey--text">
                                        E-mails com link acessado
                                    </div>
                                    <div
                                        :style="{
                                            color: titleColorFont
                                        }"
                                        class="font-weight-bold display-1 d-flex justify-center align-end"
                                    >
                                        <v-progress-circular
                                            v-if="carregandoRelatorioEmails"
                                            color="primary"
                                            small
                                            indeterminate
                                        />
                                        <div v-else>
                                            {{
                                                relatorioEmails.emails_com_link_de_contato_acessado
                                            }}
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-divider class="my-2"></v-divider>
                            <v-icon class="mr-2" small> mdi-clock </v-icon>
                            <span class="caption grey--text font-weight-light"
                                >Atualizado desde o último acesso</span
                            >
                        </v-col>
                    </v-card>
                </v-col>
            </v-row>
        </v-flex>
        <ConfirmacaoModal
            v-if="mostrarModalConfirmacao"
            :showDialog="mostrarModalConfirmacao"
            :tituloModal="tituloModalConfirmacao"
            :textoModal="textoModalConfirmacao"
            :btnConfirmarEmit="btnConfirmarModalConfirmacao"
            :btnConfirmar="'Ok'"
            :esconderCancelar="esconderCancelar"
            @confirmarAviso="aceitarModalAviso"
            @fecharModal="aceitarModalAviso"
        />
    </div>
</template>

<script>
import Vue from 'vue';
import ChartsService from '@/services/chartsService';
import MensageriaService from '@/services/MensageriaService';
import TitulosHistoricoDataTableService from '@/services/titulosHistoricoDataTableService';
import chartTotalUploads from '@/helpers/homeChart1.js';
import chartRecuperados from '@/helpers/homeChart1.js';
import ConfirmacaoModal from '@/modals/ConfirmacaoModal';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: 'home',
    components: {
        chartTotalUploads,
        chartRecuperados,
        Loading,
        ConfirmacaoModal
    },
    data() {
        return {
            headerProps: {
                sortByText: "Ordenar por",
            },
            mostrarModalConfirmacao: false,
            tituloModalConfirmacao: 'Aviso ',
            textoModalConfirmacao:
                '<b>Prezado parceiro</b><br /><br /> Você está usando um ambiente de homologação ( testes ).<br><br>Procedendo você concorda e confirma em não utilizar dados reais.<br><br> Obrigado, Equipe Recuperi',
            btnConfirmarModalConfirmacao: 'confirmarAviso',
            esconderCancelar: true,
            dataIntersect: false,
            isIntersecting: false,
            scaleClass: false,
            relatorioEmails: {
                emails_abertos: 0,
                emails_com_link_de_contato_acessado: 0,
                emails_enviados: 0,
                emails_recebidos: 0
            },
            graficos: {
                dt_inicio: null,
                dt_fim: null
            },
            ultimos30Dias: null,
            headers: [
                {
                    text: 'Status',
                    value: 'ds_status'
                },
                { text: 'Titulos', value: 'vl_count' },
                { text: 'Valor Total R$', value: 'sum_vl_saldo_protesto' }
            ],
            titulosExistentes: [],
            titulosExistentensTooltip: {
                Apontados:
                    "Títulos em fase de intimação aguardando retorno. 225 ('Apontado').",
                'Cancelados Anuídos':
                    "Títulos 121 ('Cancelado') por meio de envio de anuência (custos devedor).",
                'Cancelados Diretos':
                    "Títulos 121 ('Cancelado') por meio de envio de cancelamento (custos apresentante).",
                'Em Processo de Anuência':
                    "Títulos com status 250 ('Solicitado Anuência'), 251 ('Enviado à Anuência'), 252 ('Anuência Programada') e 144 ('Anuência confirmada no Cartório').",
                'Em Processo de Cancelamento':
                    "Títulos com status 240 ('Solicitado Cancelamento') e 241 ('Enviado a Cancelamento').",
                'Em Processo de Desistência':
                    "Títulos com status 230 ('Solicitado Desistência') e 231 ('Enviado à Desistência').",
                'Pagos / Liquidação em Condicional':
                    "Títulos pagos em cartório (valores repassados ao apresentante) 101 ('Pago') e 107 ('Liquidação em condicional').",
                Protestados: "Títulos Protestados 102 ('Protestado').",
                'Protestos Desistidos Antes do Envio':
                    "Títulos que não foram processados e enviados a protesto 233 ('Solicitação de Protesto Desistida') e 261 ('Upload cancelado').",
                'Retirados / Desistidos':
                    "Títulos retirados e desistidos por meio da desistência (custos apresentante) 103 ('Retirado').",
                'Total Irregulares':
                    "Títulos que voltaram irregular 105 ('Irregular - sem custas') e 106 ('Irregular - com custas').",
                'Não Reapresentados':
                    'Títulos Irregulares que não foram reapresentados, podem ser corrigidos e reenviados.'
            },
            colorPainel: null,
            dashboardColorFont: '#757575',
            dashboardColorFontTitle: 'white',
            titleColorFont: '#757575',
            dataTotalUpload: null,
            dataRecuperados: null,
            carregandoRelatorioEmails: false,
            carregandoDataTable: false,
            carregandoTotalUploads: false,
            carregandoChartRecuperados: false
        };
    },
    computed: {
        computedGetUserName() {
            return this.$store.getters.getUserName;
        },
        abortCtrl() {
            return this.$store.getters.getAbortCtrl;
        },
        aceitouModal() {
            return this.$store.getters.aceitouModal;
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeRouteLeave(to, from, next) {
        if (this.abortCtrl) {
            this.abortCtrl.abort();
            this.$store.commit('setAbortCtrl', new AbortController());
        }
        next();
    },
    mounted() {
        if (this.$store.getters.isPartner == 1) {
            this.usuarioPartner = true;
            this.dashboardColorFont = '#757575';
            this.dashboardColorFontTitle = 'white';
            this.titleColorFont = '#959ea6';
            this.colorPainel = '#959ea6';
        } else {
            this.dashboardColorFontTitle = 'white';
            this.dashboardColorFont = 'white';
            this.titleColorFont = this.$vuetify.theme.themes.light.primary;
            this.colorPainel = 'primary';
        }

        if (!this.aceitouModal) {
            this.mostrarModalConfirmacao = document.location.href.indexOf('painel-hom.recuperi') > -1;
        }

        const controller = new AbortController();
        this.$store.commit('setAbortCtrl', controller);
        this.getDataMounted();
        this.iniciarLoadingsMounted();
    },
    methods: {
        aceitarModalAviso() {
            this.mostrarModalConfirmacao = false;
            this.$store.commit('setAceitouModal', true);
        },
        onIntersect(entries) {
            if (this.isIntersecting === false) {
                this.isIntersecting = entries[0].isIntersecting;
            }
            if (this.isIntersecting === true && this.dataIntersect === false) {
                this.iniciarLoadings();
                this.relatorioTotal();
                this.getDataTable();
                this.dataIntersect = true;
            }
        },
        getTooltipByStatus(status) {
            return this.titulosExistentensTooltip[status];
        },
        iniciarLoadingsMounted() {
            this.carregandoTotalUploads = true;
            this.carregandoChartRecuperados = true;
        },
        iniciarLoadings() {
            this.carregandoRelatorioEmails = true;
            this.carregandoDataTable = true;
        },
        relatorioTotal() {
            let graficos = this.graficos;
            graficos.dt_inicio = null;
            graficos.dt_fim = null;

            let mensageriaService = new MensageriaService(
                Vue.http,
                this.$store
            );
            mensageriaService
                .buscarEnviosConsolidado(graficos)
                .then(
                    response => {
                        if (response.status == 200) {
                            this.relatorioEmails = response.data;
                        }
                    },
                    error => {
                        console.log(error);
                    }
                )
                .catch(e => {
                    this.mensagem = e;
                })
                .finally(() => {
                    this.carregandoRelatorioEmails = false;
                });
        },
        getDataTable() {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            //var mm_bf = String(today.getMonth()).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear() - 1;
            var yyyyate = today.getFullYear();
            let dt_de = yyyy + '-' + mm + '-' + dd;
            let dt_ate = yyyyate + '-' + mm + '-' + dd;
            let TitulosHistorico = new TitulosHistoricoDataTableService(
                Vue.http,
                this.$store
            );
            TitulosHistorico.dadosHistoricoTitulos(dt_de, dt_ate)
                .then(response => {
                    this.titulosExistentes = response.data;
                    this.carregandoDataTable = false;
                })
                .catch(e => {
                    console.log(e);
                });
        },
        fillDataTotalUpload() {
            this.dataTotalUpload = {
                labels: this.dataTotalUpload.anomes,
                datasets: [
                    {
                        label: 'Total de Títulos',
                        borderColor: 'white',
                        backgroundColor: 'white',
                        borderWidth: 4,
                        pointBorderColor: 'white',
                        pointBorderWidth: 3,
                        fill: false,
                        data: this.dataTotalUpload.titulos
                    }
                ]
            };
        },
        fillDataRecuperados() {
            this.dataRecuperados = {
                labels: this.dataRecuperados.anomes,
                datasets: [
                    {
                        label: 'Anuências',
                        borderColor: '#FFFFFF',
                        backgroundColor: '#01579B',
                        borderWidth: 4,
                        pointBorderColor: '#FFFFFF',
                        pointBorderWidth: 2,
                        fill: false,
                        data: this.dataRecuperados.anuencias
                    },
                    {
                        label: 'Cancelamentos',
                        borderColor: '#FFFFFF',
                        backgroundColor: '#01579B',
                        borderWidth: 4,
                        pointBorderColor: '#FFFFFF',
                        pointBorderWidth: 3,
                        fill: false,
                        data: this.dataRecuperados.cancelamentos
                    },
                    {
                        label: 'Desistências',
                        borderColor: '#FFFFFF',
                        backgroundColor: '#01579B',
                        borderWidth: 4,
                        pointBorderColor: '#FFFFFF',
                        pointBorderWidth: 3,
                        fill: false,
                        data: this.dataRecuperados.desistencias
                    },
                    {
                        label: 'Pagos',
                        borderColor: '#FFFFFF',
                        backgroundColor: '#01579B',
                        borderWidth: 4,
                        pointBorderColor: '#FFFFFF',
                        pointBorderWidth: 3,
                        fill: false,
                        data: this.dataRecuperados.pagos
                    },
                    {
                        label: 'Total',
                        borderColor: '#FFFFFF',
                        backgroundColor: '#8BC34A',
                        borderWidth: 4,
                        pointBorderColor: '#FFFFFF',
                        pointBorderWidth: 3,
                        fill: false,
                        data: this.dataRecuperados.total_titulos
                    }
                ]
            };
        },
        getDataMounted() {
            let ChartService = new ChartsService(Vue.http, this.$store);
            ChartService.graficoTotalTitulosUpload()
                .then(
                    response => {
                        this.dataTotalUpload = response.data;
                        this.dataTotalUpload.anomes = this.dataTotalUpload.anomes.map(
                            this.changeLabels
                        );
                        this.fillDataTotalUpload();
                    },
                    error => {
                        this.mensagem = error;
                        this.dialogLoading = false;
                        console.log(error);
                    }
                )
                .catch(e => {
                    console.log(e);
                })
                .finally(() => {
                    this.carregandoTotalUploads = false;
                });
            ChartService.graficoTitulosRecuperados()
                .then(
                    response => {
                        this.dataRecuperados = response.data;
                        this.dataRecuperados.anomes = this.dataRecuperados.anomes.map(
                            this.changeLabels
                        );
                        this.fillDataRecuperados();
                    },
                    error => {
                        this.mensagem = error;
                        this.dialogLoading = false;
                        console.log(error);
                    }
                )
                .catch(e => {
                    console.log(e);
                })
                .finally(() => {
                    this.carregandoChartRecuperados = false;
                });
        },
        changeLabels(anomes) {
            const [ano, mes] = anomes.split('-');

            const referenciaMeses = {
                '01': 'Jan',
                '02': 'Fev',
                '03': 'Mar',
                '04': 'Abr',
                '05': 'Mai',
                '06': 'Jun',
                '07': 'Jul',
                '08': 'Ago',
                '09': 'Set',
                '10': 'Out',
                '11': 'Nov',
                '12': 'Dez'
            };

            return `${referenciaMeses[mes]}/${ano}`;
        }
    }
};
</script>
<style>
.v-sheet--offset {
    top: -24px;
    position: relative;
    z-index: 2;
}

.homeGraf {
    min-height: 100px;
    font-size: 1.3rem !important;
}
</style>
