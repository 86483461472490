import { Line, mixins } from 'vue-chartjs';

export default {
    extends: Line,
    mixins: [mixins.reactiveProp],
    props: ['chartData'],
    data() {
        return {
            options: {
                //Chart.js options
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                fontColor: 'white',
                                borderColor: 'white'
                            },
                            gridLines: {
                                display: false,
                                fontColor: 'white',
                                borderColor: 'white',
                                color: 'white'
                            }
                        }
                    ],
                    xAxes: [
                        {
                            gridLines: {
                                display: false,
                                color: 'white',
                                borderColor: 'white'
                            },
                            ticks: {
                                fontColor: 'white',
                                borderColor: 'white'
                            }
                        }
                    ]
                },
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        usePointStyle: true,
                        pointStyle: true,
                        fontColor: 'white'
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                borderColor: 'white'
            }
        };
    },
    mounted() {
        this.renderChart(this.chartdata, this.options);
    }
};
