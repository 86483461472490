import axios from 'axios';

export default class ChartsService {
    constructor(http, store) {
        this._http = http;
        this._store = store;
        this._ctrl = store.getters.getAbortCtrl;
    }

    graficoTotalTitulosUpload() {
        return axios.post(
            process.env.VUE_APP_RECUPERI_API + 'graficos/total_titulos_upload',
            {
                cd_apresentante: this._store.getters.cdApresentante
            },
            {
                headers: {
                    Authorization: 'Bearer ' + this._store.getters.token
                },
                signal: this._ctrl.signal
            }
        );
    }

    graficoTitulosRecuperados() {
        return axios.get(
            process.env.VUE_APP_RECUPERI_API +
                'graficos/total_anuencias_desistencias_cancelamentos_pagos',
            {
                params: {
                    cd_apresentante: this._store.getters.cdApresentante
                },
                headers: {
                    Authorization: 'Bearer ' + this._store.getters.token
                },
                signal: this._ctrl.signal
            }
        );
    }

    getPagos(dt_de, dt_ate, cd_status) {
        return axios.get(
            process.env.VUE_APP_RECUPERI_API + 'titulo/historico/valores',
            {
                params: {
                    dt_inicio: dt_de,
                    dt_fim: dt_ate,
                    cd_status: cd_status,
                    cd_apresentante: this._store.getters.cdApresentante
                },
                headers: {
                    Authorization: 'Bearer ' + this._store.getters.token
                },
                signal: this._ctrl.signal
            }
        );
    }

    graficoEnviados() {
        return this._http.post('graficos/enviados', {
            cd_apresentante: this._store.getters.cdApresentante
        });
    }

    graficoTodosStatus(dt_de, dt_ate) {
        return this._http.post('graficos/consulta_historico_titulos', {
            cd_apresentante: this._store.getters.cdApresentante,
            dt_de: dt_de,
            dt_ate: dt_ate
        });
    }
}
