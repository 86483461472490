import axios from 'axios';

export default class AgrupadorService {
    constructor(store) {
        this._store = store;
    }

    async buscarAgrupadores() {
        try {
            const response = await axios.get(
                process.env.VUE_APP_RECUPERI_API + 'agrupador',
                {
                    params: {
                        cd_apresentante: this._store.getters.cdApresentante
                    },
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );

            return response.data;
        } catch (error) {
            throw error?.response;
        }
    }

    async buscarAgrupadoresSelect() {
        try {
            const response = await axios.get(
                process.env.VUE_APP_RECUPERI_API + 'agrupador',
                {
                    params: {
                        cd_apresentante: this._store.getters.cdApresentante
                    },
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );

            const listaAgrupadores = [
                { ds_agrupador: null, nm_agrupador: 'Sem agrupador' }
            ];
            const listaAgrupadoresFiltros = [
                { ds_agrupador: null, nm_agrupador: 'Todos' },
                {
                    ds_agrupador: 'NENHUM AGRUPADOR',
                    nm_agrupador: 'Sem agrupador'
                }
            ];

            for (let index = 0; index < response.data.length; index++) {
                const agrupador = response.data[index];
                const nm_agrupador = `${agrupador.ds_agrupador} - ${agrupador.nm_agrupador}`;
                listaAgrupadores.push({
                    ds_agrupador: agrupador.ds_agrupador,
                    nm_agrupador: nm_agrupador
                });
                listaAgrupadoresFiltros.push({
                    ds_agrupador: agrupador.ds_agrupador,
                    nm_agrupador: nm_agrupador
                });
            }

            this._store.commit('setListaAgrupadores', listaAgrupadores);
            this._store.commit(
                'setListaAgrupadoresFiltro',
                listaAgrupadoresFiltros
            );
        } catch (error) {
            throw error?.response;
        }
    }

    async criarAgrupador(agrupador) {
        try {
            const response = await axios.post(
                process.env.VUE_APP_RECUPERI_API + 'agrupador',
                {
                    cd_apresentante: this._store.getters.cdApresentante,
                    nm_agrupador: agrupador.nm_agrupador
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );

            return response?.data?.mensagem;
        } catch (error) {
            throw error?.response;
        }
    }

    async atualizarAgrupador(agrupador) {
        try {
            const response = await axios.put(
                process.env.VUE_APP_RECUPERI_API + 'agrupador',
                {
                    cd_apresentante: this._store.getters.cdApresentante,
                    nm_agrupador: agrupador.nm_agrupador,
                    id_agrupador: agrupador.id_agrupador
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );

            return response?.data?.mensagem;
        } catch (error) {
            throw error?.response;
        }
    }
}
