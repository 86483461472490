<template>
    <v-dialog
        v-model="dialogModalRelatorios"
        max-width="924px"
        persistent
        scrollable
        color="background"
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-card-title class="pa-0">
                <v-toolbar flat dark color="primary">
                    <v-btn
                        icon
                        dark
                        @click="fecharModal()"
                        :disabled="loadingSolicitar"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Relatórios Solicitados</v-toolbar-title>
                </v-toolbar>
            </v-card-title>
            <v-divider></v-divider>
            <div class="pa-4">
                <v-card-text :class="!isExtraSmall ? 'px-0' : 'px-0 pt-0'">
                    Consulte o histórico e realize o download dos seus
                    Relatórios solicitados
                </v-card-text>
                <v-data-table
                    :headers="colunasRelatoriosSolicitados"
                    :items="relatoriosSolicitados"
                    :loading="loadingInicial"
                    loading-text="Carregando Relatórios..."
                    item-key="relatorios"
                    class="elevation-1 pa-3"
                    :class="isExtraSmall ? 'alinhar-botao-editar' : ''"
                    :footer-props="{
                        itemsPerPageOptions: [10, 20, 50, -1],
                        itemsPerPageText: 'Relatórios por página:'
                    }"
                    data-cy="tabelaRelatorioSolicitados"
                >
                    <template
                        slot="item.relatorio_data_solicitacao"
                        slot-scope="props"
                    >
                        {{
                            props.item.relatorio_data_solicitacao
                                ? formatarDataHoraMoment(
                                      props.item.relatorio_data_solicitacao,
                                      'DD/MM/YYYY HH:mm:ss'
                                  )
                                : '--'
                        }}
                    </template>
                    <template slot="item.action" slot-scope="props">
                        <v-btn
                            @click="baixarRelatorio(props.item.relatorio_link)"
                            elevation="0"
                            :class="!isExtraSmall ? '' : 'mb-2 mr-3'"
                            class="green white--text"
                            :loading="loadingSolicitar"
                            :data-cy="`baixarRelatorio${props.index}`"
                            :disabled="
                                !props.item.relatorio_link ||
                                    props.item.relatorio_link === ''
                            "
                        >
                            <v-icon
                                medium
                                class="mr-2"
                                :style="{ color: 'white' }"
                                >download</v-icon
                            >Baixar
                        </v-btn>
                    </template>
                </v-data-table>
            </div>
            <v-divider></v-divider>
            <v-card-actions
                :style="
                    !isExtraSmall
                        ? 'display: flex; justify-content: end'
                        : 'display: flex; justify-content: center'
                "
            >
                <v-btn
                    class="ml-5"
                    dense
                    color="error"
                    :width="isExtraSmall ? '100' : '120'"
                    :loading="loadingSolicitar"
                    @click="fecharModal()"
                >
                    Cancelar
                </v-btn>
            </v-card-actions>
        </v-card>
        <Snackbar
            v-if="mostrarSnackbar"
            :mostrarSnackbar="mostrarSnackbar"
            :timeoutSnackbar="6000"
            :corSnackbar="corSnackbar"
            :mensagemSnackbar="mensagemSnackbar"
            @fecharSnackbar="mostrarSnackbar = false"
        />
    </v-dialog>
</template>

<script>
import Vue from 'vue';
import Snackbar from '@/components/Snackbar';
import UsuarioService from '@/services/UsuarioService';
import {
    eventFecharModal,
    getMensagemRequest,
    formatarDataHoraMoment
} from '../helpers/Utilitarios';

export default {
    name: 'ModalRelatoriosSolicitados',
    components: {
        Snackbar
    },
    props: {
        dialogModalRelatorios: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            usuarioService: new UsuarioService(Vue.http, this.$store),
            loadingInicial: false,
            isExtraSmall: false,
            loadingSolicitar: false,
            mostrarSnackbar: false,
            corSnackbar: 'green white--text',
            mensagemSnackbar: '',
            relatoriosSolicitados: [],
            colunasRelatoriosSolicitados: [
                {
                    text: 'Data Criação',
                    sortable: false,
                    value: 'relatorio_data_solicitacao',
                    align: 'left'
                },
                {
                    text: 'Status',
                    sortable: false,
                    value: 'relatorio_status',
                    align: 'center'
                },
                {
                    text: ' ',
                    sortable: false,
                    value: 'action',
                    align: 'center',
                    width: '200px'
                }
            ]
        };
    },
    beforeDestroy() {
        document.removeEventListener('keyup', this.eventFecharModal);
    },
    async mounted() {
        document.addEventListener('keyup', this.eventFecharModal);
        this.handleBreakpoints(this.breakpoint);

        await this.listarRelatorios();
    },
    methods: {
        eventFecharModal,
        getMensagemRequest,
        formatarDataHoraMoment,
        async listarRelatorios() {
            try {
                this.loadingInicial = true;
                const relatorios = await this.usuarioService.buscarRelatoriosSolicitados();
                this.relatoriosSolicitados = this.filtrarRelatoriosSemLink(
                    relatorios
                );
            } catch (e) {
                const mensagem = this.getMensagemRequest(
                    e.data,
                    'Erro ao buscar Relatórios Solicitados'
                );
                this.exibirSnackbar('red', mensagem);
            } finally {
                this.loadingInicial = false;
            }
        },
        filtrarRelatoriosSemLink(relatorios) {
            const relatoriosLinkValido = relatorios.filter(
                relatorio =>
                    relatorio.relatorio_link && relatorio.relatorio_link !== ''
            );

            if (!relatoriosLinkValido.length) {
                this.exibirSnackbar(
                    'red',
                    'Nenhum Relatório com link ativo encontrado.'
                );
            }

            return relatoriosLinkValido;
        },
        fecharModal() {
            this.$emit('fecharModal');
        },
        exibirSnackbar(corSnackbar, mensagemSnackbar) {
            this.mostrarSnackbar = true;
            this.corSnackbar = corSnackbar;
            this.mensagemSnackbar = mensagemSnackbar;
        },
        handleBreakpoints(val) {
            this.isExtraSmall = false;
            if (val.xs) {
                this.isExtraSmall = true;
            }
        },
        baixarRelatorio(relatorioLink) {
            const link = document.createElement('a');
            link.href = relatorioLink;
            link.target = '_blank';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    },
    computed: {
        breakpoint() {
            return this.$vuetify.breakpoint;
        }
    },
    watch: {
        breakpoint: {
            deep: true,
            handler(val) {
                this.handleBreakpoints(val);
            }
        }
    }
};
</script>

<style></style>
