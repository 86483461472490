import moment from 'moment';
import TituloService from '../services/TituloService';
import AgrupadorService from '../services/AgrupadorService';
import ApresentanteService from '../services/apresentanteService';
import { numerosString } from './Constantes';

export function getOnlyNumbers(string) {
    if (string && string !== '') {
        return string.match(/\d/g).join('');
    }
}

export function getLettersAndNumbers(string) {
    if (string && string !== '') {
        return string.match(/[a-zA-Z0-9]/g).join('');
    }
}

export function formatarDecimal(valor, mostrarMoeda = true) {
    if (!valor) {
        valor = 0;
    } else if (typeof valor === 'string') {
        valor = Number(valor.replace(',', '.'));
    }
    let valorFormatado = valor.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });
    if (!mostrarMoeda) {
        valorFormatado = removerSiglaDecimal(valorFormatado);
    }
    if (valor === 0) {
        valorFormatado = (mostrarMoeda ? 'R$ ' : '') + '0,00';
    }
    return `${valorFormatado}`;
}

export function removerSiglaDecimal(valor) {
    valor = valor.replace('%', '');
    valor = valor.replace('R$', '');
    valor = valor.replace('r$', '');
    valor = valor.replace(' ', '');
    valor = valor.trim();
    return valor;
}

export function getDataAtualDatePicker() {
    return new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
}

export function getDataDatePicker(data) {
    return new Date(data - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
}

export function getDataHoraString(dataHora, mostrarHora = false) {
    if (dataHora) {
        let [data, hora] = dataHora.split(' ');
        data = formatarDataHora(data);
        if (mostrarHora) {
            return `${data} ${hora}`;
        }
        return `${data}`;
    }
    return null;
}

export function formatarDataHora(data, mostrarHora = false) {
    if (data) {
        if (typeof data === 'string') {
            data = formatarDataString(data);
            data = new Date(data);
        }
        const dia = formatarDoisDigitos(data.getDate());
        const mes = formatarDoisDigitos(data.getMonth() + 1);
        const ano = data.getFullYear();
        let dataFormatada = `${dia}/${mes}/${ano}`;

        if (mostrarHora) {
            const hora = formatarDoisDigitos(data.getHours());
            const minutos = formatarDoisDigitos(data.getMinutes());
            const segundos = formatarDoisDigitos(data.getSeconds());

            dataFormatada += ` ${hora}:${minutos}:${segundos}`;
        }

        return dataFormatada;
    }
    return null;
}

export function formatarDoisDigitos(valor) {
    return String(valor).padStart(2, '0');
}

export function formatarDataString(data) {
    if (data) {
        const [ano, mes, dia] = data.split('-');
        return `${mes}-${dia}-${ano}`;
    }
    return null;
}

export function permitirLetrasNumeros(apenasMaiusculas = false) {
    const caracteres =
        'event.charCode === 42 || event.charCode >= 45 && event.charCode <= 47';
    const numeros = 'event.charCode >= 48 && event.charCode <= 57';
    const letrasMaiusculas = 'event.charCode >= 65 && event.charCode <= 90';
    const letrasMinusculas = 'event.charCode >= 97 && event.charCode <= 122';

    if (apenasMaiusculas) {
        return `return ${caracteres} || ${numeros} || ${letrasMaiusculas}`;
    }
    return `return ${caracteres} || ${numeros} || ${letrasMaiusculas} || ${letrasMinusculas}`;
}

export function getCorrectTimezoneOffsetDate(data) {
    data = data.setTime(data.getTime() + data.getTimezoneOffset() * 60 * 1000);
    return new Date(data);
}

export function getDataHoraSubtraida(dataFinal, menosDias) {
    return moment(new Date(dataFinal))
        .utcOffset('-03:00')
        .subtract(menosDias, 'days')
        .toDate();
}

export function distanciaEntreDatasEmDias(data1, data2) {
    const date1 = data1;
    const date2 = data2;
    return moment(date1).diff(date2, 'days');
}

export async function listarSituacoesTitulo(http, store, callback = null) {
    let tituloService = new TituloService(http, store);
    await tituloService.listaSituacoesTitulo(callback);
}

export async function listarAgrupadores(store, isSelect = false) {
    const agrupadorService = new AgrupadorService(store);
    if(store.getters.isLiteRo) return;

    if (isSelect) {
        const storeAgrupadores = store.getters.listaAgrupadores;
        if (!storeAgrupadores.length) {
            await agrupadorService.buscarAgrupadoresSelect();
        }

        return;
    }

    const agrupadores = await agrupadorService.buscarAgrupadores();
    return agrupadores;
}

export async function listarIrregularidades(http, store) {
    const apresentanteService = new ApresentanteService(http, store);
    await apresentanteService.buscarIrregularidadesApresentante();
}

export function getDescritivoAgrupador(store, codigo) {
    if (!codigo) {
        return null;
    }

    const storeAgrupadores = store.getters.listaAgrupadores;
    if (storeAgrupadores.length) {
        const agrupador = storeAgrupadores.find(
            agrupador => agrupador.ds_agrupador === codigo
        );

        if (agrupador) {
            return `${agrupador.nm_agrupador}`;
        }
    }

    return codigo;
}

function gerarNumeroRandom(n) {
    let num = Math.round(Math.random() * n);
    return num;
}

function mod(dividendo, divisor) {
    return Math.round(dividendo - Math.floor(dividendo / divisor) * divisor);
}

export function gerarCnpjValido() {
    let n = 9;
    let n1 = gerarNumeroRandom(n);
    let n2 = gerarNumeroRandom(n);
    let n3 = gerarNumeroRandom(n);
    let n4 = gerarNumeroRandom(n);
    let n5 = gerarNumeroRandom(n);
    let n6 = gerarNumeroRandom(n);
    let n7 = gerarNumeroRandom(n);
    let n8 = gerarNumeroRandom(n);
    let n9 = 0;
    let n10 = 0;
    let n11 = 0;
    let n12 = 1;
    let d1 =
        n12 * 2 +
        n11 * 3 +
        n10 * 4 +
        n9 * 5 +
        n8 * 6 +
        n7 * 7 +
        n6 * 8 +
        n5 * 9 +
        n4 * 2 +
        n3 * 3 +
        n2 * 4 +
        n1 * 5;
    d1 = 11 - mod(d1, 11);
    if (d1 >= 10) d1 = 0;
    let d2 =
        d1 * 2 +
        n12 * 3 +
        n11 * 4 +
        n10 * 5 +
        n9 * 6 +
        n8 * 7 +
        n7 * 8 +
        n6 * 9 +
        n5 * 2 +
        n4 * 3 +
        n3 * 4 +
        n2 * 5 +
        n1 * 6;
    d2 = 11 - mod(d2, 11);
    if (d2 >= 10) d2 = 0;
    return (
        '' +
        n1 +
        n2 +
        '.' +
        n3 +
        n4 +
        n5 +
        '.' +
        n6 +
        n7 +
        n8 +
        '/' +
        n9 +
        n10 +
        n11 +
        n12 +
        '-' +
        d1 +
        d2
    );
}

function calculateDigit(values, weights) {
    let soma = values.reduce((acc, value, index) => acc + value * weights[index], 0);
    let resto = soma % 11;
    return resto < 2 ? 0 : 11 - resto;
}

export function generateRandomCNPJ() {
    const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let root = '';
    let branch = '';
    
    for (let i = 0; i < 8; i++) {
        root += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    
    for (let i = 0; i < 4; i++) {
        branch += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    
    let cnpj = root + branch;
    let cnpjValues = cnpj.split('').map(charToValue);
    
    const peso1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    const digito1 = calculateDigit(cnpjValues.slice(0, 12), peso1);
    
    cnpjValues.push(digito1);
    const peso2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    const digito2 = calculateDigit(cnpjValues.slice(0, 13), peso2);
    
    return cnpj + digito1.toString() + digito2.toString();
}

// export function validarCNPJ(cnpj) {
//     cnpj = cnpj.replace(/[^\d]+/g, '');

//     if (cnpj == '') return false;
//     if (cnpj.length !== 14) return false;

//     if (
//         cnpj == '00000000000000' ||
//         cnpj == '11111111111111' ||
//         cnpj == '22222222222222' ||
//         cnpj == '33333333333333' ||
//         cnpj == '44444444444444' ||
//         cnpj == '55555555555555' ||
//         cnpj == '66666666666666' ||
//         cnpj == '77777777777777' ||
//         cnpj == '88888888888888' ||
//         cnpj == '99999999999999'
//     ) {
//         return false;
//     }

//     let tamanho = cnpj.length - 2;
//     let numeros = cnpj.substring(0, tamanho);
//     let digitos = cnpj.substring(tamanho);
//     let soma = 0;
//     let pos = tamanho - 7;
//     for (let i = tamanho; i >= 1; i--) {
//         soma += numeros.charAt(tamanho - i) * pos--;
//         if (pos < 2) pos = 9;
//     }
//     let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
//     if (resultado != digitos.charAt(0)) return false;

//     tamanho = tamanho + 1;
//     numeros = cnpj.substring(0, tamanho);
//     soma = 0;
//     pos = tamanho - 7;
//     for (let i = tamanho; i >= 1; i--) {
//         soma += numeros.charAt(tamanho - i) * pos--;
//         if (pos < 2) pos = 9;
//     }
//     resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
//     if (resultado != digitos.charAt(1)) return false;

//     return true;
// }
function charToValue(c) {
    if (/\d/.test(c)) {
        return parseInt(c, 10);
    } else if (/[a-zA-Z]/.test(c)) {
        return c.toLowerCase().charCodeAt(0) - 87;  // 'a' -> 10, 'b' -> 11, ..., 'z' -> 35
    } else {
        throw new Error("Caractere inválido no CNPJ");
    }
}

export function validarCNPJ(cnpj) {
    cnpj = cnpj.split('').filter(c => /[a-zA-Z0-9]/.test(c)).map(charToValue);

    if (cnpj.length !== 14) {
        return false;
    }

    // Pesos para o primeiro dígito
    const peso1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    const soma1 = cnpj.slice(0, 12).reduce((acc, val, i) => acc + val * peso1[i], 0);
    const resto1 = soma1 % 11;
    const digito1 = resto1 < 2 ? 0 : 11 - resto1;

    // Pesos para o segundo dígito
    const peso2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    const soma2 = cnpj.slice(0, 13).reduce((acc, val, i) => acc + val * peso2[i], 0);
    const resto2 = soma2 % 11;
    const digito2 = resto2 < 2 ? 0 : 11 - resto2;

    return cnpj[12] === digito1 && cnpj[13] === digito2;
}

export function gerarCodigoApresentante() {
    return Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .substr(0, 3)
        .toUpperCase();
}

export function validarFormatoData(data) {
    const regex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
    return regex.test(data);
}

export function validarDsLogin(dsLogin, cdApresentante) {
    const regex = new RegExp(`^[A-Za-z0-9._%+-]+@${cdApresentante}$`, 'i');
    return regex.test(dsLogin);
}

export function corrigirDsLogin(dsLogin, cdApresentante) {
    let loginCorrigido = '';

    if (!dsLogin.includes('@')) {
        loginCorrigido = dsLogin + `@${cdApresentante}`;
    } else {
        const [login, empresa] = dsLogin.split('@');

        if ((login && !empresa) || (login && empresa !== cdApresentante)) {
            loginCorrigido = login + '@' + cdApresentante;
        }
    }

    return loginCorrigido;
}
export function validarDsLoginAdmin(dsLogin, cdApresentante) {
    dsLogin = dsLogin.toLowerCase();

    let [usuario, dominio] = dsLogin.split('@');

    // Se o @ for de 3 dígitos, ou se for omitido, ele deve ser automaticamente preenchido com o código do apresentante.
    // Se a parte após o @ for menor que 5 caracteres, ela deve ser substituída pelo cdApresentante.
    if (!dominio || dominio.length < 5) {
        dominio = cdApresentante;
    }

    dsLogin = usuario + '@' + dominio;

    // Permitir apenas letras e números após o @
    // Permitir letras, números, hífens, underlines, e pontos antes do arroba
    const regex = new RegExp(`^[A-Za-z0-9._%+-]+@[A-Za-z0-9.]+$`, 'i');
    return regex.test(dsLogin);
}

export function corrigirDsLoginAdmin(dsLogin, cdApresentante) {
    let loginCorrigido = dsLogin;

    if (!dsLogin.includes('@')) {
        loginCorrigido = dsLogin + `@${cdApresentante}`;
    } else {
        const [login, empresa] = dsLogin.split('@');

        if ((login && !empresa) || (login && empresa.length < 5)) {
            loginCorrigido = login + '@' + cdApresentante;
        }
    }

    return loginCorrigido.toLowerCase();
}

export function validarEmail(email) {
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return regex.test(email);
}

export function validarNome(nome) {
    const regex = /^[a-zá-üA-ZÁ-Ü-' ]{3,45}/gi;
    return regex.test(nome);
}

export function validarSenha(senha) {
    // eslint-disable-next-line no-useless-escape
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{12,})/;
    return regex.test(senha);
}

export function validarAgrupador(agrupador) {
    if (!agrupador || agrupador === '') return true;

    const regex = /^[a-zA-Z0-9]{3}$/;
    return regex.test(agrupador);
}

export function stringifyNumber(number) {
    return String(number)
        .split('')
        .map(number => primeiraLetraMaiuscula(numerosString[number]))
        .join('');
}

export function primeiraLetraMaiuscula(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function eventFecharModal(e) {
    if (!e) e = window.event;
    const keyCode = e.code || e.key;

    if (keyCode === 'Escape') {
        this.$emit('fecharModal');
    }
}

export function validarStringSemEspaco(valor) {
    const regex = /^\S*$/;
    return regex.test(valor);
}

export function formatarDataHoraMoment(data, formato = 'DD/MM/YYYY') {
    const dataFormatada = moment(data).format(formato);
    return dataFormatada;
}

export function filtrarAtributosNulosObjeto(obj) {
    Object.keys(obj).forEach(key => {
        if (obj[key] === null) {
            delete obj[key];
        }
    });
}

export function getMensagemResponse(
    response,
    mensagemPadraoSucesso,
    mensagemPadraoErro
) {
    const mensagemPadrao =
        response.status === 200 ? mensagemPadraoSucesso : mensagemPadraoErro;
    const mensagemBody = response.body.mensagem
        ? response.body.mensagem
        : response.body.msg
        ? response.body.msg
        : null;
    return mensagemBody ? mensagemBody : mensagemPadrao;
}

export function getMensagemRequest(data, mensagemPadrao) {
    // Para o parametro data, pode ser enviado tanto response.body quanto response.data
    if (data?.mensagem) {
        return data.mensagem;
    }
    if (data?.msg) {
        return data.msg;
    }
    if (data?.message) {
        return data.message;
    }
    if (data?.erro) {
        return data.erro;
    }
    return mensagemPadrao;
}

export async function montarObjetoAnexoProtesto(
    ds_nosso_numero,
    arquivosSelecionados
) {
    const arquivosBase64 = [];

    for (let index = 0; index < arquivosSelecionados.length; index++) {
        const arquivo = arquivosSelecionados[index];
        const arquivoBase64 = await converterArquivoParaBase64(arquivo);
        arquivosBase64.push(arquivoBase64);
    }

    return {
        ds_nosso_numero: ds_nosso_numero,
        arquivos_selecionados: arquivosBase64
    };
}

export async function converterArquivoParaBase64(arquivo) {
    async function retornarBase64() {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = function(event) {
                const base64String = event.target.result.split(';base64,')[1];
                resolve(base64String);
            };

            reader.onerror = function(error) {
                reject(error);
            };

            reader.readAsDataURL(arquivo);
        });
    }

    try {
        const base64String = await retornarBase64(arquivo);
        return base64String;
    } catch (error) {
        console.error('Erro ao converter arquivo para base64: ', error);
        throw error;
    }
}

export const listagemUF = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO'
];
