<template>
    <v-dialog
        v-model="showDialog"
        max-width="450px"
        persistent
        color="background"
        transition="dialog-bottom-transition"
    >
        <v-card>
            <v-toolbar flat dark dense color="primary">
                <v-btn icon dark @click="fecharModal">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title class="pa-0">
                    {{ tituloModal }}
                </v-toolbar-title>
            </v-toolbar>
            <v-divider />
            <v-card-text class="py-5 px-6 text-justify content-style">
                <span v-html="textoModal" />
            </v-card-text>
            <v-divider />
            <v-card-actions class="justify-end">
                <v-btn
                    color="success"
                    @click="confirmar"
                    data-cy="confirmacaoModal"
                >
                    {{ btnConfirmar }}
                </v-btn>
                <v-btn
                    v-if="!esconderCancelar"
                    class="ml-4"
                    @click="fecharModal"
                    data-cy="fecharConfirmacaoModal"
                >
                    {{ btnCancelar }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'ConfirmacaoModal',
    props: {
        showDialog: { type: Boolean, default: false },
        esconderCancelar: { type: Boolean, default: false },
        tituloModal: { type: String, default: 'Confirmação' },
        textoModal: {
            type: String,
            default: 'Tem certeza que deseja prosseguir com a ação?'
        },
        btnConfirmarEmit: {
            type: String,
            required: true
        },
        btnConfirmar: { type: String, default: 'Confirmar' },
        btnCancelar: { type: String, default: 'Cancelar' }
    },
    data() {
        return {};
    },
    beforeDestroy() {
        document.removeEventListener('keyup', this.keyupListener);
    },
    mounted() {
        document.addEventListener('keyup', this.keyupListener);
    },
    methods: {
        fecharModal() {
            this.$emit('fecharModal');
        },
        confirmar() {
            this.$emit(this.btnConfirmarEmit);
        },
        keyupListener(e) {
            if (!e) e = window.event;
            const keyCode = e.code || e.key;
            if (keyCode === 'Escape') {
                this.fecharModal();
            } else if (keyCode === 'Enter' || keyCode === 'NumpadEnter') {
                this.confirmar();
            }
        }
    }
};
</script>

<style>
.content-style {
    font-size: 1rem;
    /* text-indent: 1.5rem; */
    border-radius: 0;
}
</style>
