<template>
    <v-snackbar
        v-model="mostrarSnackbar"
        timeout="-1"
        :color="corSnackbar"
        :top="false"
        >{{ mensagemSnackbar }}
        <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="fecharSnackbar">
                <v-icon class="ma-3">mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {
    name: 'Snackbar',
    props: {
        mostrarSnackbar: { type: Boolean, default: false },
        timeoutSnackbar: { type: Number, default: 4000 },
        corSnackbar: {
            type: String,
            default: 'green'
        },
        mensagemSnackbar: {
            type: String,
            required: true
        }
    },
    data() {
        return {};
    },
    mounted() {
        this.timeoutSnackbarEmit();
    },
    methods: {
        fecharSnackbar() {
            this.$emit('fecharSnackbar');
        },
        timeoutSnackbarEmit() {
            setTimeout(() => {
                this.fecharSnackbar();
            }, this.timeoutSnackbar);
        }
    }
};
</script>

<style></style>
