import axios from 'axios';

export default class UsuarioService {
    constructor(http, store) {
        this._http = http;
        this._store = store;
    }

    listarApresentantes(params) {
        if (params) {
            return this._http.get('apresentantes', {
                params
            });
        } else {
            return this._http.get('apresentantes');
        }
    }

    async criarApresentanteExperience(tipo) {
        try {
            const response = await axios.post(
                process.env.VUE_APP_RECUPERI_API +
                    'apresentantes/experience/' +
                    tipo,
                {},
                {
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );

            return response;
        } catch (error) {
            throw error?.response;
        }
    }

    async getFaturamentoApresentante(payload) {
        try {
            const response = await axios.get(
                process.env.VUE_APP_RECUPERI_API +
                    'apresentantes/'
                    + payload.cd_apresentante 
                    +'/faturamento/' 
                    + payload.date
                    + '/'
                    + payload.type
                    + '?page=' + payload.page
                    + '&per_page=' + payload.per_page,
                {
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );
            return response?.data;
        } catch (error) {
            throw error?.response;
        }
    }
    
    async envioRelatorioFaturamentoEmail(payload) {
        try {
            const response = await axios.get(
                process.env.VUE_APP_RECUPERI_API +
                    'apresentantes/'
                    + payload.cd_apresentante 
                    +'/faturamento/' 
                    + payload.date
                    + '/exportar',
                    
                {
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );
            return response?.data;
        } catch (error) {
            throw error?.response;
        }
    }

    async actionRevisaoInterna(action, items) {
        try {
            const response = await axios.post(
                process.env.VUE_APP_RECUPERI_API +
                    'titulos/revisao-interna/' +
                    action,
                {
                    ...items
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );

            return response;
        } catch (error) {
            throw error?.response;
        }
    }

    async getApresentantesRevisaoInterna(){
        try {
            const response = await axios.get(
                process.env.VUE_APP_RECUPERI_API + 'titulos/revisao-interna/apresentantes',
                {
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );
            return response.data;
        } catch (error) {
            throw error?.response;
        }
    }

    async getRevisoesInternas(params){
        try {
            const response = await axios.get(
                process.env.VUE_APP_RECUPERI_API + 'titulos/revisao-interna',
                {
                    params,
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );
            return response.data;
        } catch (error) {
            throw error?.response;
        }
    }

    listaPartners() {
        return this._http.get('partner/list/all');
    }

    salvarApresentante(data) {
        return this._http.post(`alterar_cd_apresentante`, {
            cd_apresentante: data
        });
    }

    criarNovoApresentante(data) {
        return this._http.post('apresentantes', data); //ROTA NOVA
    }

    detalhesApresentante(apresentante) {
        return this._http.get('apresentantes/'+apresentante.cd_apresentante);
    }

    editarApresentante(data) {
        return this._http.put('apresentantes/'+ data.cd_apresentante, data);
    }

    consultaApresentanteProtestado(p_apresentante) {
        return this._http.get('protestado/apresentante_entidade', {
            params: {
                cd_apresentante: p_apresentante
            }
        });
    }

    verificaCredenciaisApresentanteNotifica(p_apresentante) {
        return this._http.get('notifica/apresentantes/validar', {
            params: {
                cd_apresentante: p_apresentante
            }
        });
    }

    verificaDetalhesApresentanteNotifica(p_apresentante) {
        return this._http.get('notifica/apresentantes/detalhe', {
            params: {
                cd_apresentante: p_apresentante
            }
        });
    }

    habilitarApresentanteNotifica(apresentante) {
        let payload = {
            cd_apresentante: apresentante
        };
        return this._http.post('notifica/apresentantes/ativar', payload);
    }

    habilitarApresentanteProtestado(data) {
        let payload = {
            cd_apresentante: data
        };
        return this._http.post('protestado/apresentante_ativar', payload);
    }

    async apresentantePausa(cdApresentante, stPausa) {
        try {
            const response = await axios.put(
                process.env.VUE_APP_RECUPERI_API + 'apresentante/pausa',
                {
                    cd_apresentante: cdApresentante,
                    st_pausa: stPausa
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );
            return response?.data?.mensagem;
        } catch (error) {
            throw error?.response;
        }
    }

    async buscarTiposApresentante() {
        try {
            const response = await axios.get(
                process.env.VUE_APP_RECUPERI_API + 'apresentantes/tipos',
                {
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );

            return response.data;
        } catch (error) {
            throw error?.response;
        }
    }

    async buscarIrregularidadesApresentante() {
        try {
            const response = await axios.get(
                process.env.VUE_APP_RECUPERI_API +
                    'apresentantes/'+ this._store.getters.cdApresentante +'/irregularidades',
                {
                    headers: {
                        Authorization: 'Bearer ' + this._store.getters.token
                    }
                }
            );

            if (response?.data?.length > 0) {
                const listaIrregularidades = [
                    {
                        cd_irregularidade: null,
                        nm_irregularidade: 'Sem filtro'
                    }
                ];

                for (let index = 0; index < response.data.length; index++) {
                    const irregularidade = response.data[index];
                    const nm_irregularidade = `${irregularidade.cd_irregularidade} - ${irregularidade.nm_irregularidade}`;
                    listaIrregularidades.push({
                        cd_irregularidade: irregularidade.cd_irregularidade,
                        nm_irregularidade: nm_irregularidade
                    });
                }

                this._store.commit(
                    'setListaIrregularidades',
                    listaIrregularidades
                );
            }
        } catch (error) {
            throw error?.response;
        }
    }
}
