export const updatesRelease = [
    {
        title: 'Versão 2.6.4 | 05/10/2023',
        descricao:
            'Melhorias de performance na plataforma e atualizações de segurança',
        versao: '2.6.4'
    },
    {
        title: 'Versão 2.6.3 | 28/09/2023',
        descricao:
            'Melhorias de performance na plataforma e atualizações de segurança',
        versao: '2.6.3'
    },
    {
        title: 'Versão 2.6.2 | 21/09/2023',
        descricao: '',
        versao: '2.6.2'
    },
    {
        title: 'Versão 2.6.1 | 14/09/2023',
        descricao:
            'Parametros de integração Recuperi + Intima Digital, Funcionalidade de agrupadores de usuários',
        versao: '2.6.1'
    },
    {
        title: 'Versão 2.6.0 | 06/09/2023',
        descricao:
            'Parametros de integração Recuperi + Intima Digital, Funcionalidade de agrupadores de usuários',
        versao: '2.6.0'
    },
    {
        title: 'Versão 2.5.9 | 31/08/2023',
        descricao: 'Parametros de integração Recuperi + Intima Digital',
        versao: '2.5.9'
    },
    {
        title: 'Versão 2.5.8 | 24/08/2023',
        descricao: 'Parametros de integração Recuperi + Intima Digital',
        versao: '2.5.8'
    },
    {
        title: 'Versão 2.5.7 | 17/08/2023',
        descricao: 'Parametros de integração Recuperi + Intima Digital',
        versao: '2.5.7'
    },
    {
        title: 'Versão 2.5.6 | 10/08/2023',
        descricao: 'Iniciação de API para integração com Notifica',
        versao: '2.5.6'
    },
    {
        title: 'Versão 2.5.5 | 03/08/2023',
        descricao: 'Funcionalidade de agrupadores de usuários',
        versao: '2.5.5'
    },
    {
        title: 'Versão 2.5.4 | 27/07/2023',
        descricao: 'Funcionalidade de agrupadores de usuários',
        versao: '2.5.4'
    },
    {
        title: 'Versão 2.5.3 | 20/07/2023',
        descricao: 'Funcionalidade de agrupadores de usuários',
        versao: '2.5.3'
    },
    {
        title: 'Versão 2.5.2 | 13/07/2023',
        descricao: 'Funcionalidade de agrupadores de usuários',
        versao: '2.5.2'
    },
    {
        title: 'Versão 2.5.1 | 06/07/2023',
        descricao: 'Funcionalidade de agrupadores de usuários',
        versao: '2.5.1'
    },
    {
        title: 'Versão 2.5.0 | 29/06/2023',
        descricao: 'Funcionalidade de agrupadores de usuários',
        versao: '2.5.0'
    },
    {
        title: 'Versão 2.4.4 | 22/06/2023',
        descricao: 'Atualizações de versões, melhorias de segurança',
        versao: '2.4.4'
    },
    {
        title: 'Versão 2.4.3 | 15/06/2023',
        descricao: 'Atualizações de versões, melhorias de segurança',
        versao: '2.4.3'
    },
    {
        title: 'Versão 2.4.2 | 08/06/2023',
        descricao:
            'Bugfixes, atualizações de libraries, implementação de rota callback',
        versao: '2.4.2'
    },
    {
        title: 'Versão 2.4.0 | 25/05/2023',
        descricao:
            'Desenvolvimento de método callback para integrações via api',
        versao: '2.4.0'
    },
    {
        title: 'Versão 2.3.8 | 18/05/2023',
        descricao: 'Ajustes em gráfico e tela inicial',
        versao: '2.3.8'
    },
    {
        title: 'Versão 2.3.5 | 27/04/2023',
        descricao:
            'Correção de bugs e pequenas melhorias de usabilidade, Manual Fluxo de Protesto',
        versao: '2.3.5'
    },
    {
        title: 'Versão 2.3.2 | 06/04/2023',
        descricao: 'Correção de bugs e pequenas melhorias de usabilidade',
        versao: '2.2.0'
    },
    {
        title: 'Versão 2.3.1 | 30/03/2023',
        descricao: 'Correção de bugs e pequenas melhorias de usabilidade',
        versao: '2.2.0'
    },
    {
        title: 'Versão 2.3.0 | 23/03/2023',
        descricao:
            'Ferramentas de Whatsapp para Mensageria: Títulos ou Contatos',
        versao: '2.2.0'
    }
    // {
    //     title: 'Versão 2.2.0 | 16/03/2023',
    //     descricao:
    //         'Ferramentas de Whatsapp para Mensageria: Títulos ou Contatos',
    //     versao: '2.2.0'
    // },
    // {
    //     title: 'Versão 2.1.0 | 09/03/2023',
    //     descricao:
    //         'Ferramentas de Whatsapp para Mensageria: Títulos ou Contatos',
    //     versao: '2.1.0'
    // },
    // {
    //     title: 'Versão 2.0.1 | 27/02/2023',
    //     descricao:
    //         'Ferramentas de Whatsapp para Mensageria: Títulos ou Contatos',
    //     versao: '2.0.1'
    // },
    // {
    //     title: 'Versão 1.21.0 | 23/01/2023',
    //     descricao:
    //         'Histórico de logs do usuário exibido em tela, melhorias mensageria',
    //     versao: '1.21.0'
    // }
    // {
    //     title: 'Versão 1.20.0 | 16/01/2023',
    //     descricao:
    //         'Melhorias de performance, novas funcionalidades, correção de Bugs',
    //     versao: '1.20.0'
    // },
    // {
    //     title: 'Versão 1.19.0 | 21/11/2022',
    //     descricao: 'Exibição de Instrumentos de Protesto no Título',
    //     versao: '1.19.0'
    // }
    // {
    //     title: 'Versão 1.18.3 | 21/11/2022',
    //     descricao: 'Manutenção da plataforma',
    //     versao: '1.18.3'
    // },
    // {
    //     title: 'Versão 1.18.2 | 16/11/2022',
    //     descricao: 'Atualizações de segurança e manual do usuário',
    //     versao: '1.18.2'
    // },
    // {
    //     title: 'Versão 1.17.1 | 24/10/2022',
    //     descricao: 'Cancelamento de comandos via painel em Testes',
    //     versao: '1.17.1'
    // }
    // {
    //     title: 'Versão 1.17.0 | 17/10/2022',
    //     descricao: 'Cancelamento de comandos via painel',
    //     versao: '1.17.0'
    // },
    // {
    //     title: 'Versão 1.16.0 | 10/10/2022',
    //     descricao: 'Melhorias em funcionamento de e-mails transacionais',
    //     versao: '1.16.0'
    // }
    // {
    //     title: 'Versão 1.15.6 | 04/10/2022',
    //     descricao:
    //         'Atualização de segurança, e melhorias de performance, Ajuste de UX em botões de relatórios na Consulta de Títulos',
    //     versao: '1.15.6'
    // },
    // {
    //     title: 'Versão 1.15.5 | 26/09/2022',
    //     descricao:
    //         'Atualização de segurança, e melhorias de performance, correção de bugs',
    //     versao: '1.15.5'
    // }
    // {
    //     title: 'Versão 1.15.4 | 20/09/2022',
    //     descricao:
    //         'Atualização de segurança, e melhorias de performance, correção de bugs',
    //     versao: '1.15.4'
    // },
    // {
    //     title: 'Versão 1.15.3 | 12/09/2022',
    //     descricao: 'Atualização de segurança, e melhorias de performance',
    //     versao: '1.15.3'
    // },
    // {
    //     title: 'Versão 1.15.2 | 05/09/2022',
    //     descricao: 'Atualização de segurança, e melhorias de performance',
    //     versao: '1.15.2'
    // },
    // {
    //     title: 'Versão 1.15.1 | 29/08/2022',
    //     descricao: 'Atualização de segurança, e melhorias de performance',
    //     versao: '1.15.1'
    // }
    // {
    //     title: 'Versão 1.15.0 | 15/08/2022',
    //     descricao:
    //         'Melhorias no funcionamento de interface de criação de senhas e novos usuários',
    //     versao: '1.15.0'
    // },
    // {
    //     title: 'Versão 1.14.0 | 08/08/2022',
    //     descricao: 'Atualização de segurança, e melhorias de performance',
    //     versao: '1.14.0'
    // },
    // {
    //     title: 'Versão 1.13.0 | 08/08/2022',
    //     descricao:
    //         'Documentação de API atualizada, correções de bugs e melhorias de performance',
    //     versao: '1.13.0'
    // }
    // {
    //     title: 'Versão 1.12.0 | 25/07/2022',
    //     descricao: 'TR03 + Apresentante CRAN',
    //     versao: '1.12.0'
    // },
    // {
    //     title: 'Versão 1.11.3 | 18/07/2022',
    //     descricao: 'Correções de bugs e ajustes de performance',
    //     versao: '1.11.3'
    // },
    // {
    //     title: 'Versão 1.11.2 | 11/07/2022',
    //     descricao: 'Correções de bugs e ajustes de performance',
    //     versao: '1.11.2'
    // },
    // {
    //     title: 'Versão 1.11.1 | 04/07/2022',
    //     descricao: 'Correções de bugs e ajustes de performance',
    //     versao: '1.11.1'
    // }
    // {
    //     title: 'Versão 1.10.2 | 20/06/2022',
    //     descricao:
    //         'Implementação de novos mecanismos para listagem/detalhe de cartórios',
    //     versao: '1.10.2'
    // }
    // {
    //     title: 'Versão 1.10.1 | 13/06/2022',
    //     descricao: 'Implementação do mecanismo Pré-anuência',
    //     versao: '1.10.1'
    // }
    // {
    //     title: 'Versão 1.10.0 | 06/06/2022',
    //     descricao:
    //         'Atualizações no Manual do Usuário/Xls, Remoção da tabela de emolumentos',
    //     versao: '1.10.0'
    // }
    // {
    //     title: 'Versão 1.9.8 | 30/05/2022',
    //     descricao:
    //         'Otimização de velocidade/performance na plataforma e ajuste de pequenos bugs',
    //     versao: '1.9.8'
    // },
    // {
    //     title: 'Versão 1.9.7 | 23/05/2022',
    //     descricao: 'Otimização de velocidade/performance na plataforma',
    //     versao: '1.9.7'
    // }
    // {
    //     title: 'Versão 1.9.6 | 16/05/2022',
    //     descricao:
    //         'Correção de pequenos bugs, estudo para melhorias de performance',
    //     versao: '1.9.6'
    // }
    // {
    //     title: 'Versão 1.9.5 | 09/05/2022',
    //     descricao:
    //         'Correção de pequenos bugs, Implementação de testes automatizados na plataforma, estudo para melhorias de performance',
    //     versao: '1.9.5'
    // },
    // {
    //     title: 'Versão 1.9.4 | 02/05/2022',
    //     descricao:
    //         'Correção de Bugs e implementação de loading na tela inicial',
    //     versao: '1.9.4'
    // }
    // {
    //     title: 'Versão 1.9.3 | 25/04/2022',
    //     descricao:
    //         'Melhorias de funcionamento para feature Geranciar Usuarios e correções de uso',
    //     versao: '1.9.3'
    // }
    // {
    //     title: 'Versão 1.9.2 | 18/04/2022',
    //     descricao:
    //         'Melhorias de funcionamento para feature Geranciar Usuarios e correções de uso',
    //     versao: '1.9.2'
    // },
    // {
    //     title: 'Versão 1.9.1 | 11/04/2022',
    //     descricao: 'Versão com correção de bugs indentificados na plataforma.',
    //     versao: '1.9.1'
    // },
    // {
    //     title: 'Versão 1.9.0 | 04/04/2022',
    //     descricao:
    //         'Melhoria de velocidade na tela de Upload de Títulos | Informações sobre repasse de valores pelo cartório: Dentro do título é possível visualizar os valores repassados pelo cartório para cada título',
    //     versao: '1.9.0'
    // },
    // {
    //     title: 'Versão 1.8.0 | 29/03/2022',
    //     descricao:
    //         'Consulta Títulos: Agora a barra de filtros permite múltiplos status na consulta de títulos, podendo selecionar diversos status, entre eles: Protestados, Cancelados, Apontados, e trazer todos em um resultado de busca',
    //     versao: '1.8.0'
    // },
    // {
    //     title: 'Versão 1.7.1 | 21/03/2022',
    //     descricao:
    //         '- Nova fucionadade: agora as atualizações são listadas aos usuários através da seção de notificações',
    //     versao: '1.7.1'
    // }
];

export default updatesRelease;
