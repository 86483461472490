<template>
    <div>
        <v-dialog
            v-if="showModal"
            v-model="showModal"
            max-width="600px"
            persistent
            color="background"
            transition="dialog-bottom-transition"
        >
            <!-- Innformações sobre o Upload -->
            <v-card class="lembrete-expiracao-senha-component">
                <v-toolbar flat dark color="primary">
                    <v-col cols="12" sm="12" align="left">
                        <v-toolbar-title>
                            <v-icon color="white" >
                                mdi-alert-decagram
                            </v-icon>
                            Lembrete de expiração de senha
                        </v-toolbar-title>
                    </v-col>
                </v-toolbar>
                <v-card-text>
                    Olá {{ userProfile.name }}, como vai?<br/><br/>
                    Este é um lembrete que resta{{ userProfile.password_expire_in > 1 ? 'm' : '' }} <strong>{{ userProfile.password_expire_in }} dia{{ userProfile.password_expire_in > 1 ? 's' : '' }}</strong> para que sua senha de acesso expire.
                    <br/>
                    Que tal definir sua nova senha?<br/>
                    <div class="buttons">
                        <v-btn color="primary" @click="doAction('changePasswordNow')">Definir Nova Senha</v-btn>
                        <v-btn @click="doAction('changePasswordLater')">Definir a senha depois</v-btn>
                    </div>
                </v-card-text>
            </v-card>
            <!-- fim das informações sobre o Upload -->
        </v-dialog>
    </div>
</template>

<script>

import router from '@/router';

export default {
    name: 'LembreteExpiracaoSenha',
    components: {
    },
    data: () => ({
        showModal: false
    }),
    computed: {
        userProfile() {
            return this.$store.getters.user;
        },
    },
    methods: {
        doAction(action) {

            localStorage.setItem('LembreteExpiracaoSenha', true);
            this.showModal = false;

            if (action == 'changePasswordNow') {
                router.push({ name: 'MinhaConta', params: { action: 'alterar-senha' } });
            } 
        }

    },
    beforeDestroy() {

    },
    mounted() {
        let LembreteExpiracaoSenha = localStorage.getItem('LembreteExpiracaoSenha');
        if ( typeof this.userProfile.password_expire_in != 'undefined' &&
         this.userProfile.password_expire_in <= 7 &&
          LembreteExpiracaoSenha == null){
            this.showModal = true;
        }
    }
};
</script>

<style scoped lang="scss">
    .lembrete-expiracao-senha-component  {
        header.primary {
            background-color: #e67e22 !important;
        }
        .v-card__text{
            padding-top: 20px;
            .buttons {
                display: flex;
                justify-content: flex-end;
                gap: 10px;
                margin-top: 25px;

                button.primary {
                    background-color: #e67e22 !important;
                }
            }
        }
    }
</style>
